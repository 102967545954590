<template>
<!--    <div class="item_empty_list_wrap" style="margin: 20px auto; text-align: center; height: 300px">-->
<!--        리스트가 존재하지 않습니다.-->
<!--    </div>-->
  <div>
    <div class="empty_wrap">
      <div class="img_box"></div>
      <div class="text_box">{{$t('no_default_list')}}</div>
    </div>
  </div>

</template>

<script>
export default {
    name: "ItemEmptyListLayout",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>